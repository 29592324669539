import React, { createContext, useReducer } from "react"

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()

const initialState = {
  language: "pt",
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LANGUAGE": {
      return {
        ...state,
        language: action.language,
      }
    }
    default:
      break
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
